<template>
  <div class="forecast-results">
    <div class="column" style="margin-bottom:0" v-loading="loading">
      <div class="flex-row align-items-center justify-between border-bottom padding-bottom-10">
        <div class="flex-row align-items-center">
          <div>我的预测结果</div>
          <div class="margin-left-20">
            <span class="color-sub">选择时间：</span>
            <el-date-picker
              style="width: 220px;"
              size="mini"
              :key="modelFreq"
              v-model="dateRangeVlaue"
              :type="modelFreq=='week'?'daterange':'monthrange'"
              :value-format="modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM'"
              range-separator="至"
              :start-placeholder="modelFreq=='week'?'开始日期':'开始日期'"
              :end-placeholder="modelFreq=='week'?'结束月份':'结束月份'"
              :clearable="false"
              :picker-options="setDateRange"
              @change="getPredictPlan">
            </el-date-picker>
          </div>
        </div>
        <div class="flex-row align-items-center">
          <el-dropdown @command="quickExport" class="margin-right-10" v-if="$store.state.loginRes.moduleList.find(v=>{return v.moduleCode=='xia_zai'})">
            <el-button plain size="mini">快捷导出<i class="el-icon-arrow-down el-icon--right"></i></el-button>
            <el-dropdown-menu slot="dropdown">
              <template v-if="modelFreq=='month'">
                <el-dropdown-item :command="1">全产品最新未来1期预测下载</el-dropdown-item>
                <el-dropdown-item :command="3">全产品最新未来3期预测下载</el-dropdown-item>
                <el-dropdown-item :command="12">全产品最新未来12期预测下载</el-dropdown-item>
              </template>
              <template v-else>
                <el-dropdown-item :command="1">全产品最新未来1期预测下载</el-dropdown-item>
                <el-dropdown-item :command="4">全产品最新未来4期预测下载</el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button plain size="mini" @click="downLoadDataBySelect">导出所选</el-button>
          <el-button plain size="mini" @click="getComparison">对比查看</el-button>
        </div>
      </div>  

      <div class="flex-row border-bottom padding-top-10 text-nowrap">
        <div class="color-sub">选择产品：</div>
        <div class="flex-row flex-wrap">
          <div class="margin-lr-10 hover-opacity" @click="selectProduct()"><span :class="{'color-blue bold':!productIds.length}">全部</span></div>
          <div class="margin-bottom-10 margin-lr-10 hover-opacity" v-for="(item,index) in productList" :key="index" @click="selectProduct(item.productId)">
            <span :class="{'color-blue bold':productIds.indexOf(item.productId)>=0}">{{ item.productName }}</span>
          </div>
        </div>
      </div>

      <div class="flex-row border-bottom padding-top-10">
        <div class="color-sub">选择方案：</div>
        <div class="flex-row">
          <div class="margin-lr-10 hover-opacity" @click="selectexpertType()"><span :class="{'color-blue bold':!expertTypeIds.length}">全部</span></div>
          <div class="margin-bottom-10 margin-lr-10 hover-opacity" v-for="(item,index) in expertTypeList" :key="index" @click="selectexpertType(item.id)">
            <span :class="{'color-blue bold':expertTypeIds.indexOf(item.id)>=0}">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <el-table
        ref="datatable"
        :height="$root.docHeight-295"
        size="medium"
        :header-cell-class-name="row=>{return row.rowIndex==0&&row.columnIndex==0?'disable-all-selection':''}"
        :data="dataList"
        @selection-change="handleSelectionChange">
        <div slot="empty"><el-empty :image-size="150"></el-empty></div>
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column label="预测时间" prop="predictTime"></el-table-column>
        <el-table-column label="预测产品" prop="productName" width="150"></el-table-column>
        <el-table-column label="预测目标" prop="targetName"></el-table-column>
        <el-table-column label="预测方案" prop="planName"></el-table-column>
        <el-table-column label="操作" prop="" width="160">
          <template slot-scope="props">
            <div class="flex-row">
              <div class="link-text margin-right-20" @click="getPredictResult(props.row)">预测结果</div>
              <div class="link-text" @click="getBackVerify(props.row)">模型精度</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
     <!-- 预测结果弹窗 -->
     <el-dialog custom-class="custom-dialog" :visible.sync="resultDetail.visible" class="forecast-results-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true" top="100px" width="60%">
        <div slot="title">
          <el-tabs v-model="resultDetail.activeTab">
            <el-tab-pane label="预测结果" name="chart"></el-tab-pane>
            <el-tab-pane label="数据详情" name="table"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="flex-column" :style="{'height': $root.docHeight-300+'px'}" v-loading="resultDetail.loading">
          <template v-if="resultDetail.activeTab=='chart'">
            <div class="color-sub font-size-12">
              统计周期：<template v-if="resultDetail.data.startTime||resultDetail.data.endTime">{{resultDetail.data.startTime | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}至{{resultDetail.data.endTime | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}</template>
            </div>
            <div class="margin-top-20">
              <p class="color-sub">平均偏差率</p>
              <p class="font-size-20">{{resultDetail.data.deviationRateAvg | numFormat(2,'-')}}%</p>
            </div>
            <Chart :option="resultChart" class="flex-auto"></Chart>
          </template>
          <template v-if="resultDetail.activeTab=='table'">
            <el-table
              :height="$root.docHeight-300"
              :data="resultDetail.data.dataList"
              size="medium"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="80"></el-empty></div>
              <el-table-column label="时间">
                <template slot-scope="props">{{ props.row.dataCycle }}</template>
              </el-table-column>
              <el-table-column label="真实值">
                <template slot-scope="props">{{ props.row.his | numFormat(2,'-') }}</template>
              </el-table-column>
              <el-table-column label="预测值">
                <template slot-scope="props">{{ props.row.predict | numFormat(2,'-') }}</template>
              </el-table-column>
              <el-table-column label="偏差值">
                <template slot-scope="props">{{ props.row.deviationValue | numFormat(2,'-') }}</template>
              </el-table-column>
              <el-table-column label="偏差率">
                <template slot-scope="props">{{ props.row.deviationRate | numFormat(2,'-') }}{{$numFormat(props.row.deviationRate)?'%':''}}</template>
              </el-table-column>
            </el-table>
          </template>
        </div>
      </el-dialog>
       <!-- 模型精度弹窗 -->
      <el-dialog custom-class="custom-dialog" :visible.sync="verifyRateDetail.visible" class="forecast-results-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true" top="100px" width="60%">
        <div slot="title">
          <el-tabs v-model="verifyRateDetail.activeTab">
            <el-tab-pane label="模型精度" name="chart"></el-tab-pane>
            <el-tab-pane label="数据详情" name="table"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="flex-column" :style="{'height': $root.docHeight-300+'px'}" v-loading="verifyRateDetail.loading">
          <template v-if="verifyRateDetail.activeTab=='chart'">
            <div class="color-sub font-size-12">
              统计周期：<template v-if="verifyRateDetail.data.startTime||verifyRateDetail.data.endTime">{{verifyRateDetail.data.startTime | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}至{{verifyRateDetail.data.endTime | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}</template>
            </div>
            <div class="flex-row margin-top-20">
              <div style="margin-right:5%">
                <p class="color-sub">移动1期平均偏差率</p>
                <p class="font-size-20">{{verifyRateDetail.data.step1DeviationRateAvg | numFormat(2,'-')}}%</p>
              </div>
              <template v-if="activeRow.planType!='cond'&&activeRow.planType!='uncond'">
                <template v-if="modelFreq=='month'">
                  <div style="margin-right:5%">
                    <p class="color-sub">移动3期平均偏差率</p>
                    <p class="font-size-20">{{verifyRateDetail.data.step3DeviationRateAvg | numFormat(2,'-')}}%</p>
                  </div>
                  <div>
                    <p class="color-sub">移动12期平均偏差率</p>
                    <p class="font-size-20">{{verifyRateDetail.data.step12DeviationRateAvg | numFormat(2,'-')}}%</p>
                  </div>
                </template>
                <template v-if="modelFreq=='week'">
                  <div>
                    <p class="color-sub">移动4期平均偏差率</p>
                    <p class="font-size-20">{{verifyRateDetail.data.step4DeviationRateAvg | numFormat(2,'-')}}%</p>
                  </div>
                </template>
              </template>
            </div>
            <Chart :option="verifyRateChart" class="flex-auto"></Chart>
          </template>
          <template v-if="verifyRateDetail.activeTab=='table'">
            <el-table
              :height="$root.docHeight-300"
              :data="verifyRateDetail.data.dataList"
              size="medium"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="80"></el-empty></div>
              <el-table-column label="时间">
                <template slot-scope="props">{{props.row.dataCycle}}</template>
              </el-table-column>
              <el-table-column label="真实值">
                <template slot-scope="props">{{props.row.his | numFormat(2,'-')}}</template>
              </el-table-column>
              <el-table-column label="移动1期" align="center">
                <el-table-column label="验证值">
                  <template slot-scope="props">{{props.row.step1Value | numFormat(2,'-')}}</template>
                </el-table-column>
                <el-table-column label="偏差值">
                  <template slot-scope="props">{{props.row.step1DeviationValue | numFormat(2,'-')}}</template>
                </el-table-column>
                <el-table-column label="偏差率">
                  <template slot-scope="props">{{props.row.step1DeviationRate | numFormat(2,'-')}}{{ $numFormat(props.row.step1DeviationRate)?'%':'' }}</template>
                </el-table-column>
              </el-table-column>
              <template v-if="modelFreq=='month'&&activeRow.planType!='cond'&&activeRow.planType!='uncond'">
                <el-table-column label="移动3期" align="center">
                  <el-table-column label="验证值">
                    <template slot-scope="props">{{props.row.step3Value | numFormat(2,'-')}}</template>
                  </el-table-column>
                  <el-table-column label="偏差值">
                    <template slot-scope="props">{{props.row.step3DeviationValue | numFormat(2,'-')}}</template>
                  </el-table-column>
                  <el-table-column label="偏差率">
                    <template slot-scope="props">{{props.row.step3DeviationRate | numFormat(2,'-')}}{{ $numFormat(props.row.step3DeviationRate)?'%':'' }}</template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="移动12期" align="center">
                  <el-table-column label="验证值">
                    <template slot-scope="props">{{props.row.step12Value | numFormat(2,'-')}}</template>
                  </el-table-column>
                  <el-table-column label="偏差值">
                    <template slot-scope="props">{{props.row.step12DeviationValue | numFormat(2,'-')}}</template>
                  </el-table-column>
                  <el-table-column label="偏差率">
                    <template slot-scope="props">{{props.row.step12DeviationRate | numFormat(2,'-')}}{{ $numFormat(props.row.step12DeviationRate)?'%':'' }}</template>
                  </el-table-column>
                </el-table-column>
              </template>
              <template v-if="modelFreq=='week'&&activeRow.planType!='cond'&&activeRow.planType!='uncond'">
                <el-table-column label="移动4期" align="center">
                  <el-table-column label="验证值">
                    <template slot-scope="props">{{props.row.step4Value | numFormat(2,'-')}}</template>
                  </el-table-column>
                  <el-table-column label="偏差值">
                    <template slot-scope="props">{{props.row.step4DeviationValue | numFormat(2,'-')}}</template>
                  </el-table-column>
                  <el-table-column label="偏差率">
                    <template slot-scope="props">{{props.row.step4DeviationRate | numFormat(2,'-')}}{{ $numFormat(props.row.step3DeviationRate)?'%':'' }}</template>
                  </el-table-column>
                </el-table-column>
              </template>
            </el-table>
          </template>
        </div>
      </el-dialog>
      <!-- 对比弹窗 -->
     <el-dialog custom-class="custom-dialog" :visible.sync="comparisonDetail.visible" class="forecast-results-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true" top="100px" width="60%">
        <div slot="title">
          <el-tabs v-model="comparisonDetail.activeTab">
            <el-tab-pane label="预测结果" name="chart"></el-tab-pane>
            <el-tab-pane label="数据详情" name="table"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="flex-column" :style="{'height': $root.docHeight-300+'px'}" v-loading="comparisonDetail.loading">
          <template v-if="comparisonDetail.activeTab=='chart'">
            <div class="color-sub font-size-12">
              统计周期：<template v-if="comparisonDetail.data.startTime||comparisonDetail.data.endTime">{{comparisonDetail.data.startTime | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM-dd')}}至{{comparisonDetail.data.endTime | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM-dd')}}</template>
            </div>
            <Chart :option="comparisonChart" class="flex-auto"></Chart>
          </template>
          <template v-if="comparisonDetail.activeTab=='table'">
            <el-table
              :height="$root.docHeight-300"
              :data="comparisonDetail.data.dataList"
              size="medium"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="80"></el-empty></div>
              <el-table-column label="时间">
                <template slot-scope="props">{{ props.row.dataCycle }}</template>
              </el-table-column>
              <el-table-column v-for="(item,index) in comparisonDetail.data.items" :key="index" :label="item.name">
                <template slot-scope="props">{{ props.row[item.id] | numFormat(2,'-') }}</template>
              </el-table-column>
            </el-table>
          </template>
        </div>
      </el-dialog>
  </div>
</template>

<script>
import Chart from "@/components/Chart";
import exportExcel  from "@/common/exportExcel";
export default {
  name: 'ForecastResults',
  components:{
    Chart,
  },
  props:{
    modelFreq:{
      type:String,
      default:'week'
    },
    productList:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data (){
    return{
      setDateRange: {
        disabledDate: time => {
          // 禁用今天之后的日期【当前天可选】
          return time > new Date();
        },
        // firstDayOfWeek:1
      },
      dateRangeVlaue:'',//选择的时间范围
      productIds:[],//选择的产品
      expertTypeList:[{name:'模型自估预测',id:'ai'},{name:'卓创赋值预测',id:'sci'},{name:'滨化赋值预测',id:'befar'},{name:'自定义模型预测',id:'custom'}],
      expertTypeIds:[],//选择的产品
      multipleSelection: [],//勾选的列表
      loading:false,
      dataList: [],
      resultDetail:{//预测结果弹窗
        visible:false,
        activeTab:'chart',
        data:{}
      },
      verifyRateDetail:{//模型精度弹窗
        visible:false,
        activeTab:'chart',
        data:{}
      },
      comparisonDetail:{//对比弹窗
        visible:false,
        activeTab:'chart',
        data:{}
      },
      activeRow:{}
    }
  },
  computed:{
    //预测结果图表
    resultChart(){
      let chartData=this.resultDetail.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.xAxis.boundaryGap = true
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        opt.legend.data=chartData.items.filter(v=>{return v.id!='deviationValue'}).map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity: v.id!='deviationRate'?0:1
            }
          }
        })
        opt.yAxis=[
          {
            type: 'value',
            name:chartData.items.find(v=>{return v.id=='his'}).unit,
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine: {
              lineStyle: {
                type:'dashed'
              }
            },
            scale:true,
          },
          {
            type: 'value',
            name:'',
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine:{
              show:false
            },
            axisLabel:{
              formatter: '{value}%'
            },
            min:0,
            max:100
          }
        ]
        opt.series=chartData.items.filter(v=>{return v.id!='deviationValue'}).map(v=>{
          let isLine=(v.id!='deviationRate')
          return isLine? {
            name: v.name,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id!='his'?"dashed":"solid"
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }:{ 
            name: v.name,
            type: 'bar', 
            yAxisIndex:1,
            barMaxWidth:15,  
            // barGap:'-35%',
            itemStyle:{ 
              borderRadius:[10,10,0,0],
              opacity:0.5
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit||""}</div>`
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
    //模型精度图表
    verifyRateChart(){
      let chartData=this.verifyRateDetail.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.xAxis.boundaryGap = true
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        opt.legend.data=chartData.items.filter(v=>{return v.id!='step1DeviationValue'&&v.id!='step3DeviationValue'&&v.id!='step4DeviationValue'&&v.id!='step12DeviationValue'}).map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity: v.id!='step1DeviationRate'&&v.id!='step3DeviationRate'&&v.id!='step4DeviationRate'&&v.id!='step12DeviationRate'?0:1
            }
          }
        })
        opt.yAxis=[
          {
            type: 'value',
            name:chartData.items.find(v=>{return v.id=='his'}).unit,
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine: {
              lineStyle: {
                type:'dashed'
              }
            },
            scale:true,
          },
          {
            type: 'value',
            name:'',
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine:{
              show:false
            },
            axisLabel:{
              formatter: '{value}%'
            },
            min:0,
            max:100
          }
        ]
        opt.series=chartData.items.filter(v=>{return v.id!='step1DeviationValue'&&v.id!='step3DeviationValue'&&v.id!='step4DeviationValue'&&v.id!='step12DeviationValue'}).map(v=>{
          let isLine=(v.id!='step1DeviationRate'&&v.id!='step3DeviationRate'&&v.id!='step4DeviationRate'&&v.id!='step12DeviationRate')
          return isLine? {
            name: v.name,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id!='his'?"dashed":"solid"
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }:{ 
            name: v.name,
            type: 'bar', 
            yAxisIndex:1,
            barMaxWidth:15,  
            barGap:'-35%',
            

            itemStyle:{ 
              borderRadius:[10,10,0,0],
              opacity:0.5
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit||""}</div>`
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
    //对比图表
    comparisonChart(){
      let chartData=this.comparisonDetail.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity: 0
            }
          }
        })
        // opt.yAxis.name=chartData.items.find(v=>{return v.expertType=='his'}).unit
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.expertType!='his'?"dashed":"solid"
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit||""}</div>`
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
  },
  created(){
    this.initDate()
    this.getData()
  },
  mounted(){

  },
  methods:{
    getData(){
      if(this.productList.length){
        this.getPredictPlan()
      }
    },
    //初始化日期
    initDate(){
      let currentDate = new Date(); // 获取当前日期
      currentDate.setMonth(currentDate.getMonth() - (this.modelFreq=='week'?1:2)); // 将当前日期减去N天
      this.dateRangeVlaue = this.modelFreq=='week'?[currentDate.format('yyyy-MM-dd'),new Date().format('yyyy-MM-dd')]:[currentDate.format('yyyy-MM'),new Date().format('yyyy-MM')]
    },
    
    //选择产品
    selectProduct(id){
      if(id){
        let index=this.productIds.indexOf(id)
        if(index>=0){
          this.productIds.splice(index,1)
        }else{
          this.productIds.push(id)
        }
      }else{
        this.productIds=[]
      }
      this.getPredictPlan()
    },
    //选择方案类型
    selectexpertType(id){
      if(id){
        let index=this.expertTypeIds.indexOf(id)
        if(index>=0){
          this.expertTypeIds.splice(index,1)
        }else{
          this.expertTypeIds.push(id)
        }
      }else{
        this.expertTypeIds=[]
      }
      this.getPredictPlan()
    },
    //勾选列表
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if(val.length>6){
        this.$refs.datatable.toggleRowSelection(val[val.length-1],false);
        this.$message({message: '最多选择6个预测方案',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      }
    },
    //获取列表信息
    getPredictPlan(){
      this.loading=true
      let request=this.$instance.get('/resultView/getPredictPlan',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           startDate:this.dateRangeVlaue[0],
           endDate:this.dateRangeVlaue[1],
           productId:this.productIds.length?this.productIds.join(','):this.productList.map(v=>{return v.productId}).join(','),
           expertType:this.expertTypeIds.join(',')
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.dataList=res.data.info
        }else{
          this.dataList=[]
        }
      })
      request.catch(()=>{
        this.loading=false
        this.targetInfo=[]
      })
      return request
    },
    //获取预测结果
    getPredictResult(row){
      this.activeRow=row
      this.resultDetail.visible=true
      this.resultDetail.activeTab='chart'
      this.resultDetail.loading=true
      let request=this.$instance.get('/resultView/getPredictResult',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           publishId:row.publishId
          },
      })
      request.then(res=>{
        this.resultDetail.loading=false
        if(res.data.code==0){
          this.resultDetail.data=res.data.info
        }else{
          this.resultDetail.data={}
        }
      })
      request.catch(()=>{
        this.resultDetail.loading=false
        this.resultDetail.data={}
      })
      return request
    },
    //获取预测结果
    getBackVerify(row){
      this.activeRow=row
      this.verifyRateDetail.visible=true
      this.verifyRateDetail.activeTab='chart'
      this.verifyRateDetail.loading=true
      let request=this.$instance.get('/resultView/getBackVerify',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           publishId:row.publishId
          },
      })
      request.then(res=>{
        this.verifyRateDetail.loading=false
        if(res.data.code==0){
          this.verifyRateDetail.data=res.data.info
        }else{
          this.verifyRateDetail.data={}
        }
      })
      request.catch(()=>{
        this.verifyRateDetail.loading=false
        this.verifyRateDetail.data={}
      })
      return request
    },
    //获取对比结果
    getComparison(){
      if(!this.multipleSelection.length){
        this.$message({message: '请选择要对比的预测方案',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      this.comparisonDetail.visible=true
      this.comparisonDetail.activeTab='chart'
      this.comparisonDetail.loading=true
      let request=this.$instance.get('/resultView/getComparisonView',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           publishIds:this.multipleSelection.map(v=>{return v.publishId}).join(',')
          },
      })
      request.then(res=>{
        this.comparisonDetail.loading=false
        if(res.data.code==0){
          this.comparisonDetail.data=res.data.info
        }else{
          this.comparisonDetail.data={}
        }
      })
      request.catch(()=>{
        this.comparisonDetail.loading=false
        this.comparisonDetail.data={}
      })
      return request
    },
    //导出所选
    downLoadDataBySelect(){
      if(this.multipleSelection.length==0){
        this.$message({message: '请选择要下载的预测方案',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false;
      }
      let query={
        modelFreq:this.modelFreq,
        publishIds:this.multipleSelection.map(v=>{return v.publishId}).join(',')
      }
      exportExcel('/resultView/downLoadDataBySelect',query,'get');
    },
    //快捷导出
    quickExport(command) {
      let query={
        modelFreq:this.modelFreq,
        cycle:command
      }
      exportExcel('/resultView/downLoadDataByCycle',query,'get');
    },
  },
  
  watch: {
    'productList':{
      handler:function(){
        this.getData()
      },
      deep:true,
    },
    "modelFreq" : {
     handler:function(){
      this.initDate()
      this.getData()
     },
     deep:true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

 
</style>
<style lang="scss">

  .forecast-results-dialog{
    .el-tabs__header{margin: 0;}
    .el-dialog__body{
      padding-top:0px
    }
  }

</style>